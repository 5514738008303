import {
  select,
  takeEvery,
  take,
  put,
  all,
  call,
  cancel,
  fork,
  ForkEffect,
  TakeEffect,
  CancelEffect,
  takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/reducers';
import {
  searchRequest,
  searchSuccess,
  searchFailure,
  getCampAdRequest,
  getCampAdSuccess,
  getCampAdFailure,
  getCampsCountRequest,
  getCampsCountSuccess,
  getCampsCountFailure,
  searchCamps,
  addOffset,
  addEmptyCampsOffset,
  getEmptyCampsCntRequest,
  getEmptyCampsCntSuccess,
  getEmptyCampsCntFailure,
  getEmptyCampsRequest,
  getEmptyCampsSuccess,
  getEmptyCampsFailure,
  addAutoOffset,
  getEmptySitesRequest,
  getEmptySitesSuccess,
  getEmptySitesFailure,
  setEmptySitesOffset,
  longTermSearchRequest,
  longTermSearchSuccess,
  longTermSearchFailure,
  longTermSearchCountRequest,
  longTermSearchCountFailure,
  longTermSearchCountSuccess,
  getSearchCampsRequest,
  getSearchCampsMoreRequest,
  getSearchCampsSuccess,
  getSearchCampsMoreSuccess,
  getSearchCampsFailure,
  getSearchAdFailure,
  getSearchAdRequest,
  getSearchAdSuccess,
  getSearchResultCountRequest,
  getSearchResultCountSuccess,
  getSearchResultCountFailure,
} from 'store/reducers/search';
import {
  createFetchAction,
  createFetchActionForSequence,
} from 'store/sagas/createFetchAction';
import {
  search,
  getCampCount,
  getEmptyCampsCnt,
  getEmptyCamps,
  getSearchAd,
  getEmptySites,
  getSearchResultCamps,
  getSearchResultAd,
  getSearchResultCount,
  getInventoryFilters,
} from 'api';
import {
  ISearchRequestPayload,
  IGetEmptyCampsRequestPayload,
  IGetSearchCampsRequest,
} from 'store/types';
import {
  ILocationTable,
  ISearchResultCamp,
  ISelectedSearchFilter,
  IZoneSiteExhibitionCamp,
} from '@types';
import { customHistory } from 'App';
import { Task } from 'redux-saga';
import { isEqual } from 'lodash';
import { getLongTermCamps, getLongTermCampsCount } from 'api/longTerm';
import { finishLoading } from 'store/reducers/loading';

const LIMIT = 10;
const SITE_CAMP_LIMIT = 6;
const CAMP_RESULT_LIMIT = 6;

const queryString = require('query-string');

const getLocations = (state: RootState) => state.filterReducer.locations;

function* goToSearchResults(
  action: PayloadAction<
    Partial<ISearchRequestPayload> &
      IGetEmptyCampsRequestPayload & { camp: string }
  >,
) {
  const locations: ILocationTable[] = yield select(getLocations);

  let queryObj = action.payload;

  let majorsArr: string[] = [];

  locations.forEach(l =>
    l.items.forEach(i => {
      if (i.city.name === queryObj.city) {
        const majors = queryObj.majors?.split(',');

        if (majors?.length) {
          majors.forEach(s => {
            i.subCity.forEach(sub => {
              if (sub.name === s) {
                majorsArr = [...majorsArr, sub.name];
              }
            });
          });
        }

        queryObj = {
          ...queryObj,
          city: i.city.name,
          majors: majorsArr.join(','),
        };
      }
    }),
  );

  const { isAll, camp, ...queryObjExceptIsAll } = queryObj;
  const query: string = queryString.stringify(
    { ...queryObjExceptIsAll, camp: camp?.trim() },
    {
      skipNull: true,
      skipEmptyString: true,
      arrayFormat: 'comma',
    },
  );

  if (window.location.pathname === '/search/geo') {
    customHistory.replace(`/search/geo?${query}`);
    return;
  }

  if (queryObj.isAll) {
    window.location.pathname === '/search'
      ? customHistory.push(`/search/result?${query}`)
      : window.location.replace(`/search/result?${query}`);
  } else {
    window.location.pathname === '/'
      ? customHistory.push(`/search/vacancy?${query}`)
      : window.location.replace(`/search/vacancy?${query}`);
  }
}

function* getMoreAutoCamps() {
  while (true) {
    const action: PayloadAction = yield take(addAutoOffset.type);
    const parsed = queryString.parse(window.location.search);
    const offset: number = yield select(
      (state: RootState) => state.searchReducer.offset,
    );

    yield put(
      searchRequest({
        search: parsed.camp,
        city: parsed.city,
        majors: parsed.majors,

        types: parsed.types,
        surroundingLeisureTypes: parsed.surroundingLeisureTypes,
        floorTypes: parsed.floorTypes,
        services: parsed.services,
        parkingTypes: parsed.parkingTypes,
        enterTypes: parsed.enterTypes,
        activities: parsed.activities,

        checkInTimestamp: parsed.checkInTimestamp,
        checkoutTimestamp: parsed.checkoutTimestamp,
        skip: offset,
        limit: LIMIT,
        sort: parsed.sort,
        onlinePayment: parsed.onlinePayment,
      }),
    );
  }
}

function* getMoreCamps() {
  while (true) {
    const action: PayloadAction<number> = yield take(addOffset.type);

    const parsed = queryString.parse(window.location.search);

    yield put(
      searchRequest({
        search: parsed.camp,
        city: parsed.city,
        majors: parsed.majors,

        types: parsed.types,
        surroundingLeisureTypes: parsed.surroundingLeisureTypes,
        floorTypes: parsed.floorTypes,
        services: parsed.services,
        parkingTypes: parsed.parkingTypes,
        enterTypes: parsed.enterTypes,
        activities: parsed.activities,

        checkInTimestamp: parsed.checkInTimestamp,
        checkoutTimestamp: parsed.checkoutTimestamp,
        skip: action.payload,
        limit: LIMIT,
        sort: parsed.sort,
      }),
    );
  }
}

function* getMoreAutoEmptyCamps() {
  while (true) {
    const action: PayloadAction = yield take(addEmptyCampsOffset.type);
    const parsed = queryString.parse(window.location.search);
    const offset: number = yield select(
      (state: RootState) => state.searchReducer.emptyCampsOffset,
    );

    yield put(
      getEmptyCampsRequest({
        search: parsed.camp,
        city: parsed.city,
        majors: parsed.majors,
        types: parsed.types,
        checkInTimestamp: parsed.checkInTimestamp,
        checkoutTimestamp: parsed.checkoutTimestamp,
        skip: offset,
        limit: LIMIT,
      }),
    );
  }
}

function* getEmptySitesSaga(
  action: PayloadAction<IGetEmptyCampsRequestPayload>,
): Generator<any, void, any> {
  let hasNext = true;
  let totalCamps: IZoneSiteExhibitionCamp[] = [];
  const offset: number = yield select(
    (state: RootState) => state.searchReducer.emptySitesOffset,
  );

  let skip = offset;
  let tryCount = 0;
  const MAX_TRIES = 3;

  while (hasNext) {
    try {
      if (tryCount >= MAX_TRIES) {
        yield put(getEmptySitesFailure('데이터 개수가 모자랍ㄴ디ㅏ'));
        yield put(finishLoading(getEmptySitesRequest.type));
        tryCount = 0;
        break;
      }

      const { camps, hasNext: nextPageAvailable } = yield call(
        createFetchActionForSequence(
          getEmptySites,
          getEmptySitesSuccess,
          getEmptySitesFailure,
        ),
        { ...action, payload: { ...action.payload, skip } },
      );

      skip += SITE_CAMP_LIMIT;
      hasNext = nextPageAvailable;
      totalCamps = totalCamps.concat(camps);

      if (totalCamps.length >= SITE_CAMP_LIMIT - 1 || !hasNext) {
        yield put(setEmptySitesOffset(skip));
        break;
      }

      tryCount += 1;
    } catch (error: any) {
      yield put(getEmptySitesFailure(error.message));
      break;
    }
  }
}

function* watchGetEmptySites(): Generator<
  ForkEffect | TakeEffect | CancelEffect,
  void,
  any
> {
  let lastTask: Task | undefined;
  let lastAction: PayloadAction | undefined;

  while (true) {
    const action: PayloadAction = yield take(getEmptySitesRequest.type);
    if (lastTask?.isRunning() && isEqual(lastAction?.payload, action.payload)) {
      yield cancel(lastTask as Task);
    }

    lastTask = (yield fork(getEmptySitesSaga as any, action)) as Task;
  }
}

export default function* searchCampSaga() {
  yield all([
    takeEvery(
      getCampsCountRequest.type,
      createFetchAction(
        getCampCount,
        getCampsCountSuccess,
        getCampsCountFailure,
      ),
    ),
    takeEvery(
      longTermSearchCountRequest.type,
      createFetchAction(
        getLongTermCampsCount,
        longTermSearchCountSuccess,
        longTermSearchCountFailure,
      ),
    ),
    takeEvery(searchCamps.type, goToSearchResults),
    getMoreCamps(),
    getMoreAutoCamps(),
    getMoreAutoEmptyCamps(),
    takeEvery(
      searchRequest.type,
      createFetchAction(search, searchSuccess, searchFailure),
    ),
    takeLatest(
      getCampAdRequest.type,
      createFetchAction(getSearchAd, getCampAdSuccess, getCampAdFailure),
    ),
    takeLatest(
      getSearchAdRequest.type,
      createFetchAction(
        getSearchResultAd,
        getSearchAdSuccess,
        getSearchAdFailure,
      ),
    ),
    takeEvery(
      getEmptyCampsCntRequest.type,
      createFetchAction(
        getEmptyCampsCnt,
        getEmptyCampsCntSuccess,
        getEmptyCampsCntFailure,
      ),
    ),
    takeEvery(
      getEmptyCampsRequest.type,
      createFetchAction(
        getEmptyCamps,
        getEmptyCampsSuccess,
        getEmptyCampsFailure,
      ),
    ),
    watchGetEmptySites(),
    takeLatest(
      getSearchCampsRequest.type,
      createFetchAction(
        getSearchResultCamps,
        getSearchCampsSuccess,
        getSearchCampsFailure,
      ),
    ),
    takeLatest(
      getSearchCampsMoreRequest.type,
      createFetchAction(
        getSearchResultCamps,
        getSearchCampsMoreSuccess,
        getSearchCampsFailure,
      ),
    ),
    takeLatest(
      getSearchResultCountRequest.type,
      createFetchAction(
        getSearchResultCount,
        getSearchResultCountSuccess,
        getSearchResultCountFailure,
      ),
    ),
    takeLatest(
      longTermSearchRequest.type,
      createFetchAction(
        getLongTermCamps,
        longTermSearchSuccess,
        longTermSearchFailure,
      ),
    ),
  ]);
}
