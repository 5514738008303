import { customHistory } from 'App';
import { AxiosError, AxiosResponse } from 'axios';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  getAnotherPartnerList,
  getShopbyItemDetail,
  getCamfitStoreItemDetail,
} from 'api';
import {
  getItemDetailFailure,
  getItemDetailRequest,
  getItemDetailSuccess,
  getAnotherPartnerListFailure,
  getAnotherPartnerListRequest,
  getAnotherPartnerListSuccess,
  getItemOptionRequest,
  getItemOptionSuccess,
  getItemOptionError,
  saveShopbyImagesRequest,
  saveShopbyImagesSuccess,
  saveShopbyImagesError,
  getShopbyMallInfoRequest,
  getShopbyMallInfoSuccess,
  getShopbyMallInfoError,
  getStoreItemDetailRequest,
  getStoreItemDetailSuccess,
  getStoreItemDetailFailure,
  getItemSubInfoRequest,
  getItemSubInfoSuccess,
  getItemSubInfoError,
} from 'store/reducers/store';
import {
  IGetShopbyMallInfoResponsePayload,
  IItemDetail,
  IResponse,
  IShopbySaveImagePayload,
  ITShopbyErrorRes,
} from '@types';
import { PayloadAction } from '@reduxjs/toolkit';
import { startLoading, finishLoading } from 'store/reducers/loading';
import { postFeedbackLog } from 'hooks/useGetTrackId';
import { RootState } from 'store/reducers';
import {
  getItemOption,
  getMalls,
  uploadShopbyImageFiles,
} from '../../api/shopby';
import {
  createFetchAction,
  createShopbyFetchAction,
} from './createFetchAction';

function* getAnotherPartnerListSaga() {
  yield takeEvery(
    getAnotherPartnerListRequest.type,
    createFetchAction(
      getAnotherPartnerList,
      getAnotherPartnerListSuccess,
      getAnotherPartnerListFailure,
    ),
  );
}

function* getStoreItemDetailSaga() {
  yield takeEvery(
    getStoreItemDetailRequest.type,
    createFetchAction(
      getCamfitStoreItemDetail,
      getStoreItemDetailSuccess,
      getStoreItemDetailFailure,
      undefined,
      function* onNotFounError(res: IResponse) {
        if (res.status === 'fail') {
          yield customHistory.replace('/store/items/notfound');
        }
      },
    ),
  );
}

function* getItemDetilaSaga() {
  yield takeEvery(
    getItemDetailRequest.type,
    createFetchAction(
      getShopbyItemDetail,
      getItemDetailSuccess,
      getItemDetailFailure,
      function* onSuccess(item: IItemDetail) {
        const visitedPages: string[] = yield select(
          (state: RootState) => state.feedbackLogReducer.visitedPages,
        );

        // // Iteration-7 스토어심폐소생 FeedbackLog-5-1
        yield postFeedbackLog({
          action: 'view',
          target: 'item',
          targetId: item.baseInfo.productNo.toString(),
          targetParam: {
            product_id: item.product?.id || undefined,
          },
          visitedPages,
        });
      },
      function* onNotFounError(res: IResponse) {
        if (res.status === 'fail') {
          // res.message === '상품 정보를 찾을 수 없습니다'
          yield customHistory.replace('/products/items/notfound');
        }
      },
    ),
  );
}

function* getItemOptionSaga() {
  yield takeEvery(
    getItemOptionRequest.type,
    createShopbyFetchAction(
      getItemOption,
      getItemOptionSuccess,
      getItemOptionError,
      undefined,
      undefined,
      function onError(errorRes: AxiosResponse<ITShopbyErrorRes>) {
        if (errorRes?.data?.message) alert(errorRes?.data?.message);
      },
    ),
  );
}

export function* saveShopbyImagesSaga() {
  yield takeLatest(
    saveShopbyImagesRequest.type,
    function* uploadImage(action: PayloadAction<FormData[]>) {
      const { type, payload } = action;
      yield put(startLoading(type));

      try {
        const data: IShopbySaveImagePayload[] = yield call(
          uploadShopbyImageFiles,
          payload,
        );

        yield put(saveShopbyImagesSuccess(data));
      } catch (e: any | AxiosError) {
        yield put(saveShopbyImagesError(e.response?.data || e));
      }

      yield put(finishLoading(type));
    },
  );
}

function* getShopbyMallInfoSaga() {
  yield takeLatest(
    getShopbyMallInfoRequest.type,
    createShopbyFetchAction(
      getMalls,
      getShopbyMallInfoSuccess,
      getShopbyMallInfoError,
      undefined,
      function onSuccess(
        data: Omit<IGetShopbyMallInfoResponsePayload, 'settingTimestamp'>,
      ) {
        const newData = {
          settingTimestamp: new Date().getTime(),
          ...data,
        } as IGetShopbyMallInfoResponsePayload;
        window.localStorage.setItem(
          'camfit_mall_info',
          JSON.stringify(newData),
        );
      },
      function* onError(errorRes: AxiosResponse<ITShopbyErrorRes>) {
        yield alert(
          `데이터를 불러오는데 오류가 발생했습니다. 해당 페이지에 다시 진입해주세요. ${errorRes.data.message}`,
        );
        yield customHistory.goBack();
      },
    ),
  );
}

function* getItemSubInfoSaga() {
  yield takeEvery(
    getItemSubInfoRequest.type,
    createFetchAction(
      getShopbyItemDetail,
      getItemSubInfoSuccess,
      getItemSubInfoError,
    ),
  );
}

export default function* storeSaga() {
  yield all([
    getStoreItemDetailSaga(),
    getAnotherPartnerListSaga(),
    getItemDetilaSaga(),
    getItemOptionSaga(),
    saveShopbyImagesSaga(),
    getShopbyMallInfoSaga(),
    getItemSubInfoSaga(),
  ]);
}
