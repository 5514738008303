import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IItemDetail } from '@types';
import { IStoreSearchState } from 'store/types';
import { IStorePopularSearchKeyword } from 'types/store/storeTypes';

const initialState: IStoreSearchState = {
  count: 0,
  items: [],
  price: {
    minPrice: 0,
    maxPrice: 0,
  },
  brands: [],
  hotItems: [],
  paging: {
    skip: 0,
    limit: 0,
    hasNext: false,
  },
  error: null,
  autoCompleteKeywordList: [],
  recommendKeywordList: [],
  popularKeywordList: [],
};

const storeSearchSlice = createSlice({
  name: 'storeSearch',
  initialState,
  reducers: {
    storeSearchCountRequest: (
      state,
      action: PayloadAction<{
        search: string;
        minPrice?: number;
        maxPrice?: number;
        brands?: string;
        sort?: string;
      }>,
    ) => {
      state.error = null;
    },
    storeSearchCountSuccess: (
      state,
      action: PayloadAction<{
        data: {
          count: number;
        };
      }>,
    ) => {
      state.count = action.payload.data.count;
      state.error = null;
    },
    storeSearchCountFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    storeSearchRequest: (
      state,
      action: PayloadAction<{
        search: string;
        minPrice?: number;
        maxPrice?: number;
        brands?: string;
        sort?: string;
        skip: number;
        limit: number;
      }>,
    ) => {
      const { skip, limit } = action.payload;

      state.paging = {
        skip,
        limit,
        hasNext: true,
      };

      state.items = [];
      state.error = null;
    },
    storeSearchMoreRequest: (
      state,
      action: PayloadAction<{
        search: string;
        minPrice?: number;
        maxPrice?: number;
        brands?: string;
        sort?: string;
        skip: number;
        limit: number;
      }>,
    ) => {
      const { skip, limit } = action.payload;

      state.paging = {
        skip,
        limit,
        hasNext: true,
      };
      state.error = null;
    },
    storeSearchSuccess: (
      state,
      action: PayloadAction<{
        data: {
          items: IItemDetail[];
          hasNext: boolean;
          price: {
            minPrice: number;
            maxPrice: number;
          };
          brands: {
            brandName: string;
            count: number;
          }[];
          hotItems: IItemDetail[];
        };
      }>,
    ) => {
      const { items, hasNext, price, brands, hotItems } = action.payload.data;

      state.items = [...state.items, ...items];
      state.paging.hasNext = hasNext;
      state.price = price;
      state.brands = brands;
      state.hotItems = hotItems;
      state.error = null;
    },
    storeSearchFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    storeSearchAutoCompleteKeywordRequest: (
      state,
      action: PayloadAction<{ keyword: string }>,
    ) => {
      state.error = null;
    },
    storeSearchAutoCompleteKeywordSuccess: (
      state,
      action: PayloadAction<{ data: { keyword: string[] } }>,
    ) => {
      state.autoCompleteKeywordList = action.payload.data.keyword;
      state.error = null;
    },
    storeSearchAutoCompleteKeywordFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    storeSearchRecommendKeywordRequest: (state, action: PayloadAction) => {
      state.error = null;
    },
    storeSearchRecommendKeywordSuccess: (
      state,
      action: PayloadAction<{ data: { keywords: string[] } }>,
    ) => {
      state.recommendKeywordList = action.payload.data.keywords;
      state.error = null;
    },
    storeSearchRecommendKeywordFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    storeSearchPopularKeywordRequest: (state, action: PayloadAction) => {
      state.error = null;
    },
    storeSearchPopularKeywordSuccess: (
      state,
      action: PayloadAction<{
        data: { popularSearches: IStorePopularSearchKeyword[] };
      }>,
    ) => {
      state.popularKeywordList = action.payload.data.popularSearches;
      state.error = null;
    },
    storeSearchPopularKeywordFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },
  },
});

export const {
  storeSearchCountRequest,
  storeSearchCountSuccess,
  storeSearchCountFailure,

  storeSearchRequest,
  storeSearchMoreRequest,
  storeSearchSuccess,
  storeSearchFailure,

  storeSearchAutoCompleteKeywordRequest,
  storeSearchAutoCompleteKeywordSuccess,
  storeSearchAutoCompleteKeywordFailure,

  storeSearchRecommendKeywordRequest,
  storeSearchRecommendKeywordSuccess,
  storeSearchRecommendKeywordFailure,

  storeSearchPopularKeywordRequest,
  storeSearchPopularKeywordSuccess,
  storeSearchPopularKeywordFailure,
} = storeSearchSlice.actions;

export default storeSearchSlice.reducer;
