import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStoreHomeState } from 'store/types';
import {
  IStoreCollection,
  IStoreShowcase,
  IStoreTheme,
} from 'types/store/storeTypes';

const initialState: IStoreHomeState = {
  collectionList: [],
  showcaseList: [],
  themeList: [],
  requestStatus: {
    collectionListStatus: false,
    showcaseListStatus: false,
    themeListStatus: false,
  },
  error: null,
};

export const camfitStoreHomeSlice = createSlice({
  name: 'camfitStoreHome',
  initialState,
  reducers: {
    getStoreCollectionListRequest: (state, action) => {
      state.error = null;
    },
    getStoreCollectionListSuccess: (
      state,
      action: PayloadAction<{ data: IStoreCollection[] }>,
    ) => {
      state.collectionList = action.payload.data;
    },
    getStoreCollectionListFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    getStoreShowcaseListRequest: (state, action) => {
      state.error = null;
    },
    getStoreShowcaseListSuccess: (
      state,
      action: PayloadAction<{ data: IStoreShowcase[] }>,
    ) => {
      state.showcaseList = action.payload.data;
    },
    getStoreShowcaseListFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    getStoreThemeListRequest: (state, action) => {
      state.error = null;
    },
    getStoreThemeListSuccess: (
      state,
      action: PayloadAction<{ data: IStoreTheme[] }>,
    ) => {
      state.themeList = action.payload.data;
    },
    getStoreThemeListFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    setRequestStatus: (
      state,
      action: PayloadAction<{
        key: 'showcaseListStatus' | 'themeListStatus' | 'collectionListStatus';
        value: boolean;
      }>,
    ) => {
      state.requestStatus[action.payload.key] = action.payload.value;
    },
  },
});

export const {
  getStoreCollectionListRequest,
  getStoreCollectionListSuccess,
  getStoreCollectionListFailure,

  getStoreShowcaseListRequest,
  getStoreShowcaseListSuccess,
  getStoreShowcaseListFailure,

  getStoreThemeListRequest,
  getStoreThemeListSuccess,
  getStoreThemeListFailure,

  setRequestStatus,
} = camfitStoreHomeSlice.actions;

export default camfitStoreHomeSlice.reducer;
